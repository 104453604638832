.newsletterSubscribe {
  h3 {
    text-align: center;
    margin: var(--cg-gutter-md) 0;
  }
  form {
    margin-top: var(--cg-gutter-md);
    display: flex;
    flex-direction: column;
    & > * {
      margin-bottom: var(--cg-gutter-md);
    }
    label {
      display: flex;
      flex-direction: column;
    }
  }
}
