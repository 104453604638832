.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

$max-opacity: 0.95;

.notification {
  box-sizing: border-box;
  padding: 10px;
  border-radius: 2px;
  color: #fff;
  font-weight: normal;
  background-color: #ccc;
  cursor: pointer;
  font-size: $text-size-md;
  line-height: 1.2em;
  position: relative;
  opacity: $max-opacity;
  margin-top: 15px;
}

.notification .title {
  font-size: $text-size-lg;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification a {
  text-decoration: underline;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  opacity: $max-opacity;
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  opacity: $max-opacity;
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
}

.notification-info {
  background-color: var(--cg-notification-container);
}

.notification-success {
  background-color: $cg-green;
}

.notification-warning {
  background-color: $cg-orange;
}

.notification-error {
  background-color: var(--cg-danger-text);
}
