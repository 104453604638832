input {
  padding: 0.5em;
  font-size: var(--cg-text-md);
  border: none;
  border-radius: 0;
  color: var(--cg-field-text);
  font-weight: 400;
  font-family: var(--cg-font), "Helvetica", "Arial";
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  background-color: $cg-grey-lightest;
  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: var(--cg-field-placeholder);
  }

  &:-ms-input-placeholder {
    color: var(--cg-field-placeholder);
  }

  &::placeholder {
    color: var(--cg-field-placeholder);
  }
}

label > input:not([type="checkbox"]),
label > .select {
  margin-top: 9px;
}

input,
input:active,
input:focus {
  outline: none;
}

label > .checkbox {
  button {
    color: $cg-black;
    border: 1px solid $cg-black;
    border-radius: 0;
    padding: 0;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    line-height: 1;
    svg {
      width: 13px;
      height: 100%;
    }
  }
}

textarea {
  background-color: $cg-grey-lightest;
}
