#catalogue {
  padding: 0 var(--cg-gutter-md);
  @extend .container;
  .pagination {
    &:not(.mobile) {
      padding: var(--cg-gutter-md) 0;
      h1,
      p,
      a,
      li {
        font-size: var(--cg-text-lg);
        font-weight: 400;
      }
      display: grid;
      grid-gap: var(--cg-gutter-md);
      grid-template-columns: 1fr 3fr;
      align-items: center;
      justify-content: space-between;
      .left {
        align-items: center;
        display: flex;
        justify-content: space-between;
        .title {
          @include lessThan(md) {
            display: none;
          }
        }
      }
      .right {
        align-items: center;
        display: flex;
        justify-content: space-between;
        .sorter {
          @include lessThan(md) {
            display: none;
          }
          .defaultElement {
            display: flex;
          }
          position: relative;
          display: flex;
          align-items: center;
          button {
            display: flex;
            align-items: center;
            padding: 5px;
            padding-top: 0;
            line-height: 1;
            font-size: $cg-text-lg;
            letter-spacing: inherit;
            text-transform: inherit;
            i {
              margin-left: 5px;
            }
          }
          .menu {
            width: 200px;
            z-index: 3;
            -webkit-user-select: none; /* webkit (safari, chrome) browsers */
            -moz-user-select: none; /* mozilla browsers */
            -khtml-user-select: none; /* webkit (konqueror) browsers */
            -ms-user-select: none; /* IE10+ */
            position: absolute;
            top: 30px;
            background-color: var(--cg-cta-container);
            border: 1px solid black;
            display: none;
            @include moreThan(md) {
              display: block;
            }
            ul {
              display: flex;
              flex-direction: column;
              padding: 10px;
              li {
                &.active {
                  text-decoration: underline;
                }
                a {
                  font-size: $cg-text-md;
                }
                padding: 5px;
                margin: 0;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .page {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .pageCount {
            @include lessThan(md) {
              display: none;
            }
          }
          .perPage {
            margin-left: $gutter;
            font-size: $cg-text-lg;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            span {
              color: $cg-grey-light;
            }
            button {
              font-size: inherit;
              line-height: 1;
              &:not(.active) {
                color: $cg-grey-light;
              }
              padding: 0 3px;
            }
          }
          #viewSwitch {
            margin-left: var(--cg-gutter-md);
            display: flex;
            align-items: center;
            button {
              &:nth-child(1) {
                margin-right: var(--cg-gutter-md);
              }
              padding: 0;
              border-radius: 0;
              i {
                font-size: $text-size-lg;
              }
            }
          }
        }
        ul.naviguator {
          display: flex;
          li {
            padding: 0;
            color: $cg-black;
            margin-left: var(--cg-gutter-md);
          }
        }
      }
    }

    &.mobile {
      display: flex;
      flex-direction: column;
      a,
      p {
        font-weight: normal;
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--gutter) 0;
        .viewSwitch {
          button {
            padding: 0 calc(var(--cg-gutter-md) / 2);
          }
        }
        .naviguator {
          li {
            padding: 0;
            padding-left: calc(var(--gutter) / 2);
            &.disabled {
              opacity: 0.2;
            }
            a {
              font-weight: normal;
              font-size: $cg-text-md;
            }
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        border-top: 1px dashed $cg-black;
        padding-top: 20px;
        align-items: center;
        .sorter {
          .defaultElement {
            display: flex;
          }
          button {
            display: flex;
            align-items: center;
            padding: 5px;
            padding-top: 0;
            line-height: 1;
            font-size: $cg-text-md;
            letter-spacing: inherit;
            text-transform: inherit;
            i {
              margin-left: 5px;
            }
          }
          .menu {
            display: flex;
            flex-direction: column;
            a {
              font-weight: normal;
              font-size: $cg-text-md;
            }
            ul {
              display: flex;
              flex-direction: column;
              li {
                padding: 0;
                color: $cg-grey-light;
                &.active {
                  color: $cg-black;
                }
              }
            }
          }
        }
        .pageCount {
          font-size: $cg-text-md;
        }
      }
    }
  }

  #filtersAndContent {
    padding: var(--cg-gutter-md) 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--cg-gutter-md);
    @include moreThan(lg) {
      grid-template-columns: 1fr 3fr;
    }
    .loader {
      text-align: center;
    }
    #filters {
      @include moreThan(lg) {
        padding-bottom: var(--cg-gutter-md);
      }
      &.hidden {
        display: none;
      }
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: calc(var(--cg-gutter-md) / 2);
      display: flex;
      flex-direction: column;
      hr {
        margin: 15px 0;
      }
      .filterSection {
        .header {
          h2,
          button {
            font-size: var(--cg-text-xs);
            font-weight: 600;
            margin-bottom: 10px;
            text-transform: uppercase;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            letter-spacing: $font-letter-spacing-lg;
            i {
              margin-left: calc(var(--cg-gutter-md) / 2);
            }
          }
          align-items: center;
          display: flex;
          justify-content: space-between;
          font-weight: normal;
          margin-bottom: calc(var(--cg-gutter-md) / 2);
        }
        &.row {
          grid-column: 1 / 3;
          .filter {
          }
        }
        &.column {
          .filterEntries {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
          .filter {
            padding-left: 0;
            margin-left: 0;
            label {
              cursor: pointer;
              &.checkbox {
                display: flex;
                align-items: center;
                flex-direction: row;
                padding: 0 2px;
              }
            }
          }
        }
        .priceRange {
          max-width: 98%;
          margin-left: 5px;
          padding-top: calc(var(--cg-gutter-md) / 2);
          padding-bottom: calc(var(--cg-gutter-md) / 2);
        }
        margin-top: 0;
        .filter {
          display: inline-block;
          padding: 2px 4px 2px 0;
          a:hover {
            border-bottom: 1px dotted $cg-primary;
            text-decoration: none;
          }
          &.selected:not(.column) {
            color: white;
            background-color: $cg-primary;
          }
        }
      }
      &:first-child {
        border-top: 1px dotted black;
        padding-top: var(--cg-gutter-md);
      }
      #clearFilters {
        button.clearContent {
          width: 100%;
          align-items: center;
          display: flex;
          justify-content: space-between;
          font-size: var(--cg-text-xs);
          font-weight: 600;
          text-transform: uppercase;
          padding: 0;
          margin: 0;
          letter-spacing: 1.67px;
        }
        text-transform: uppercase;
      }
      .showSoldOut {
        label {
          align-items: center;
          display: flex;
        }
      }
    }
    #catalogueEntries {
      #catalogueEntriesContent.tileType {
        display: grid;
        grid-gap: var(--cg-gutter-md);
        justify-content: space-between;
        grid-template-columns: repeat(2, 1fr);
        @include moreThan(sm) {
          grid-template-columns: repeat(3, 1fr);
        }
        @include moreThan(md) {
          grid-template-columns: repeat(3, 1fr);
        }
        @include moreThan(lg) {
          grid-template-columns: repeat(4, 1fr);
        }
      }
      #catalogueEntriesContent.listType {
        display: grid;
        grid-gap: 0;
        justify-content: space-between;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
