#myDetails {
  display: grid;
  grid-template-columns: 2fr 1fr;
  @include lessThan(lg) {
    grid-template-columns: 1fr;
  }
  .header {
    h1 {
      font-size: $text-size-lg;
    }
    display: flex;
    justify-content: space-between;
    margin-bottom: $gutter;
    border-bottom: 1px dotted black;
    padding-bottom: calc(var(--cg-gutter-md) / 2);
    margin-bottom: calc(var(--gutter) * 2);
  }
  section {
    margin-bottom: calc(var(--cg-gutter-md) * 3);
  }
  .content {
    label {
      display: flex;
      flex-direction: column;
    }
    label.checkbox {
      flex-direction: row;
    }
    .inline {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $gutter;
      @include lessThan(md) {
        grid-template-columns: 1fr;
      }
    }
    .mainDetails {
      display: grid;
      grid-gap: $gutter;
      grid-template-columns: 1fr 1fr;
      @include lessThan(md) {
        grid-template-columns: 1fr;
      }
    }

    .subscribe {
      flex-direction: row;
      align-items: center;
    }

    .addresses {
      .content {
        display: grid;
        grid-gap: $gutter;
        grid-template-columns: 1fr 1fr;
        @include lessThan(lg) {
          grid-template-columns: 1fr;
        }
        button.delete {
          margin-top: $gutter;
        }
        .addressFields {
          display: grid;
          grid-gap: $gutter;
          label {
            width: 100%;
          }
        }
      }
    }

    .closeAccount {
      margin-top: calc(var(--cg-gutter-md) * 3);
      button.red {
        margin-top: calc(var(--cg-gutter-md) * 1.5);
      }
    }
  }
}
