.carouselContainer {
  .slick-dots {
    display: flex !important;
    justify-content: center;
    display: inline-block;
    vertical-align: middle;
    margin: $gutter 0;
    padding: 0;
    text-align: center;
    li {
      &.slick-active {
        button {
          background-color: $cg-primary;
        }
      }
      button {
        width: 10px;
        height: 10px;
        border: none;
        background: $cg-secondary;
        color: transparent;
        cursor: pointer;
        display: block;
        padding: 0;
        border-radius: 20px;
      }
      margin: 0 5px;
    }
  }
  .slick-slider {
    overflow: hidden;
    min-width: 100%;
    width: 0;
    img {
      padding-right: var(--cg-gutter-md);
      width: 100%;
      min-width: 100%;
      display: inline-block;
    }
  }
}
