@import "reset";
@import "variables";
@import "@common-ground-io/common-assets/styles/scss/all.scss";
@import "animations";
@import "mixins";
@import "common/index";
@import "components/index";
@import "pages/index";
@import "components/cookies.scss";

body {
  margin: 0;
  padding: 0;
  color: var(--cg-default-text);
  font-family: "Inter", "Helvetica", "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--cg-text-md);
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

#app {
  position: relative;
  min-height: 100vh;
  #domainSelect {
    display: flex;
    justify-content: center;
    padding: 5px;
    .select {
      width: 30vw;
    }
  }
  .container {
    margin: 0 auto;
    max-width: $wide;
  }
}

.loader {
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
  }
}

p {
  line-height: 1.2;
}

hr {
  border: none;
  border-bottom: 1px dotted var(--cg-default-text);
}

#contentWrapper {
  padding-bottom: 16rem;
  @include moreThan(lg) {
    padding-bottom: 12rem;
  }
}

:root {
  @include moreThan(lg) {
    font-size: var(--cg-text-sm);
  }
  @include moreThan(lg) {
    font-size: var(--cg-text-md);
  }
}

.mobileMenuOpen {
  overflow: hidden !important;
  height: 100% !important;
}
