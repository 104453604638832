.inventorySearch {
  z-index: 3;
  position: relative;
  .searchArea {
    border-radius: 0;
    background-color: var(--cg-field-background);
    align-items: center;
    color: $cg-black;
    display: flex;
    justify-content: space-between;
    input {
      background-color: transparent;
    }
    i {
      margin-right: var(--gutter);
    }
  }
  .suggestions {
    width: 300%;
    max-height: 50vh;
    position: absolute;
    border: 1px solid $cg-primary;
    background-color: white;
    top: 40px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    @include lessThan(lg) {
      width: auto;
      top: 40px;
    }
    .suggestion {
      padding: calc(var(--cg-gutter-md) / 4) !important;
      display: flex;
      align-items: center;
      &:nth-child(even) {
        background-color: var(--cg-cta-container);
      }
      img {
        margin-right: 5px;
        max-width: 30px;
      }
      a,
      p {
        font-size: $text-size-sm;
      }
      &.selected {
        background-color: var(--cg-cta-container);
      }
    }
  }
}
