.pagination {
  &,
  nav {
    display: flex;
    justify-content: space-between;
    ul {
      display: flex;
      li {
        display: inline;
        padding: 0.5em;
      }
    }
    li,
    p {
      font-weight: bold;
      font-size: var(--cg-text-lg);
    }
  }
}
