#theme {
  font-family: "Inter";
  height: auto;
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  color: $cg-grey;
  button,
  h2 {
    text-transform: uppercase;
  }
}
