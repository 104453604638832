button {
  &:disabled {
    // cursor: not-allowed;
  }
  &:hover {
    color: inherit;
  }
  font-size: var(--cg-text-md);
  margin: 0;
  color: inherit;
  font-weight: normal;
  font-family: inherit;
  border: none;
  background-color: transparent;
  &.primary {
    text-transform: uppercase;
    padding: calc(var(--cg-gutter-md) / 2) var(--cg-gutter-md);
    letter-spacing: 1px;
    background-color: var(--cg-cta-container);
    border-color: var(--cg-cta-border);
    border-width: var(--cg-global-border-width);
    border-radius: $border-radius;
    border-style: var(--cg-global-border-style);
    &:hover {
      color: var(--cg-cta-hover-text);
      background-color: var(--cg-cta-hover-container);
      border-color: var(--cg-cta-hover-border);
    }
    &:active {
      color: var(--cg-cta-pressed-text);
      background-color: var(--cg-cta-pressed-container);
      border-color: var(--cg-cta-pressed-border);
    }
  }
  align-content: center;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  & {
    outline: none;
  }
  &.red {
    text-transform: uppercase;
    padding: calc(var(--cg-gutter-md) / 2) var(--cg-gutter-md);
    letter-spacing: 1px;
    border: 1px solid #ff5353;
    color: #ff5353;
    &:hover {
      background-color: #fff1f1;
    }
    &:active {
      background-color: #ffdede;
    }
  }

  &.player {
    font-size: var(--cg-text-sm);
    border: none;
    padding: 0;
  }
}

.track {
  padding: 2px;
  line-height: 1.75;
  display: grid;
  grid-template-columns: 0.25fr 0.45fr 3fr 0.7fr;
  justify-content: space-between;
  .position,
  .description,
  .duration {
    display: inline-block;
    justify-content: space-between;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &.playing {
    .duration span,
    .position span,
    .description span {
      border-bottom: 1px dotted black;
    }
  }
  .duration {
    text-align: right;
  }
  p {
    margin: 0;
  }
  &:not(.playable) {
    i {
      color: var(--cg-preset-legal);
      opacity: 0.4;
    }
  }
  button.play {
    padding: 0;
    text-align: left;
    &:disabled {
      cursor: default;
    }
    [class^="cg-icon-"],
    [class*=" cg-icon-"] {
      text-align: left;
      font-size: $cg-text-sm;
    }
  }
  &.playable:hover .duration > span,
  &.playable:hover .position > span,
  &.playable:hover .description > span {
    border-bottom: 1px dotted black;
  }
  &.playable:hover {
    cursor: pointer;
  }
}
