.topHeader {
  background-color: var(--cg-super-header-background);
  color: var(--cg-super-header-text);
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(var(--cg-gutter-md) / 4) var(--cg-gutter-md);
    font-size: var(--cg-text-sm);
    p,
    a {
      font-size: inherit;
    }
    text-transform: uppercase;
    #left {
    }
    #right {
      display: flex;
      .links {
        display: flex;
        align-items: center;
        p {
          margin: 0 calc(var(--cg-gutter-md) / 2);
        }
        @include lessThan(md) {
          display: none;
        }
        button.openCookies {
          font-size: $text-size-sm;
          text-transform: uppercase;
        }
      }

      .languageSelector {
        position: relative;
        height: auto;
        overflow-y: visible;
        margin-left: calc(var(--cg-gutter-md) * 2);
        button {
          text-transform: uppercase;
          text-align: left;
          padding: 5px 10px;
          font-size: inherit;
        }
        .current {
          display: flex;
          button {
            i {
              margin-left: 5px;
              font-size: var(--cg-text-md);
            }
          }
        }
        .entries {
          position: absolute;
          opacity: 1;
          z-index: 3;
          background-color: var(--cg-super-header-background);
          top: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }
      }
    }
  }
}
