#cookies {
  background-color: white;
  z-index: 1;
  cursor: pointer;
  position: fixed;
  margin-bottom: var(--cg-gutter-md);
  bottom: 2vw;
  border-radius: 5px;
  width: 96vw;
  margin: 0 auto;
  left: 2vw;
  @include lessThan(lg) {
    width: 90vw;
    left: 5%;
  }
  display: flex;
  box-sizing: border-box;
  padding: var(--cg-gutter-md);
  box-shadow: 0 0 10px 0 rgba(215, 215, 215, 0.3);
  p {
    margin: 0;
    button {
      padding: 0;
      text-decoration: underline;
    }
  }
  .buttons {
    display: flex;
    button {
      text-transform: uppercase;
      font-size: $text-size-sm;
    }
    @include lessThan(lg) {
      width: 100%;
      justify-content: space-between
    }
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  p {
    max-width: 60%;
    margin-bottom: 0;
    @include lessThan(lg) {
    margin-bottom: var(--gutter);
    max-width: none;
    }
    color: inherit;
  }
  @include lessThan(lg) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

#cookiesModal {
  margin: auto 0;
  max-width: 680px;
  
  form {
    & > * {
      margin-bottom: var(--gutter);
    }
  }
 
  .inline {
    display: flex;
    justify-content: space-between;
    @include moreThan(sm) {
      justify-content: flex-start;
      .acceptSelection {
        margin-right: calc(var(--cg-gutter-lg) * 1);
      }
    }
    button {
      text-transform: uppercase;
    }
  }
  .cookieOption {
    display: flex;
    align-items: flex-start;
    .cookieDescription {
      margin-left: var(--gutter);
    }
  }
  a {
    text-decoration: underline;
  }
}
