.menu {
  .dropdown {
    position: relative;
    .top {
      a {
        display: flex;
        align-items: center;
        padding-top: 0;
        line-height: 1;
        letter-spacing: inherit;
        text-transform: inherit;
      }
      i {
        font-size: $text-size-lg;
        margin-left: 5px;
      }
      button {
        display: flex;
      }
    }
    .subMenu {
      width: 200px;
      z-index: 3;
      -webkit-user-select: none; /* webkit (safari, chrome) browsers */
      -moz-user-select: none; /* mozilla browsers */
      -khtml-user-select: none; /* webkit (konqueror) browsers */
      -ms-user-select: none; /* IE10+ */
      position: absolute;
      top: 30px;
      background-color: var(--cg-header-background);
      display: none;
      @include moreThan(md) {
        display: block;
      }
      ul {
        li {
          padding: 10px;
          margin: 0;
          color: var(--cg-header-text);
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

nav.defaultNavbar {
  color: var(--cg-header-text);
  a {
    color: inherit;
  }
}

nav.defaultNavbar:not(.mobile) {
  background-color: var(--cg-header-background);
  .content {
    @extend .container;
    display: flex;
    display: grid;

    padding: var(--cg-gutter-md);
    display: grid;
    grid-gap: calc(var(--cg-gutter-md) * 2);
    grid-template-columns: 0.8fr 1fr 2.2fr 1fr;
    align-items: center;

    font-size: var(--cg-text-md);

    align-items: center;
    justify-content: space-between;
    padding: calc(var(--gutter) / 2) var(--gutter);

    .image {
      max-height: var(--cg-global-logo-height);
      min-width: 100px;
      max-width: 100%;
      img {
        max-width: 100%;
        max-height: var(--cg-global-logo-height);
      }
    }
    .group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      list-style: none;
      padding: var(--gutter);
      & > * {
        margin-right: calc(var(--cg-gutter-md) * 2);
      }
    }
    .search {
      .searchArea {
        height: 40px;
      }
    }
    .homeOrLogin {
      display: flex;
      justify-content: flex-end;
    }
  }
}

nav.defaultNavbar.mobile {
  background-color: var(--cg-header-background);
  .content {
    display: flex;
    flex-direction: column;
    &.open {
      z-index: 99 !important;
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: var(--cg-header-background);
      box-sizing: border-box;
      overflow: scroll;
      top: 0;
      .search {
        display: none;
      }
    }
    &.closed {
        .topHeader {
          display:none;
        }
      }
    .header {
      padding: 0 var(--cg-gutter-md);
      margin: calc(var(--cg-gutter-md) / 1) 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        img {
          min-height: 15px;
          max-height: 80px;
        }
      }
      .right {
        button {
          padding: 0;
          color: var(--cg-header-text);
          font-size: $cg-text-lg;
        }
      }
    }
    .entries {
      font-size: var(--cg-text-lg);
      background-color: var(--cg-header-background);
      padding: 0;
      margin-bottom: 0;
      overflow: hidden;
      &.open {
          width: 100%;
          padding-bottom: var(--cg-gutter-xl);
          box-sizing: border-box;
          display: block;
          overflow: auto;
          max-height: 1000px;
          transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease-in-out;
          opacity: 1;
      }
      &.closed {
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        max-height: 0px;
        opacity: 0;
      }
      .group {
        display: flex;
        flex-direction: column;
      }
      nav {
        & > .link {
          padding: calc(var(--cg-gutter-md) / 2) var(--cg-gutter-md);
        }
      }    
      .dropdown {
        .dropDownTitle {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: calc(var(--cg-gutter-md) / 2) var(--cg-gutter-md);
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
          &.open {
            i {
              transform: rotate(180deg);
            }
          }
        }
        ul.subMenuMobile {
          &.closed {
            display:none;
          }
          &.open {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            li {
              width: 100%;
              display: block;
              list-style: none;
              list-style-type: none;
              padding: calc(var(--cg-gutter-md) / 2) var(--cg-gutter-md);
              a {
                margin: 0;
              }
            }
          }
        }
      }
      .homeOrLogin {
        .home {
            display: block;
            width: 100%;
            padding: calc(var(--cg-gutter-md) / 2) var(--cg-gutter-md);
          }
        span {
          display: flex;
          flex-wrap: wrap;
          .basket,
          .login {
            width: 100%;
            padding: calc(var(--cg-gutter-md) / 2) var(--cg-gutter-md);
          }
        }   
      }
    }
    .search {
      padding: var(--cg-gutter-md);
      .searchArea {
        display: flex;
        justify-content: space-between;
        input {
          font-size: 17px;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
  }
}
