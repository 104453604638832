#thankyou {
  width: 100%;
  .content {
    text-align: center;
    padding: calc(var(--cg-gutter-md) * 2);
    display: flex;
    flex-direction: column;
    & > * {
      display: block;
      margin-bottom: var(--cg-gutter-md);
    }
    .homeLink {
      display: none;
    }
  }
}
