$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$wide: 1440px;

$breakpoints: (
  "xs": $xs,
  "sm": $sm,
  "md": $md,
  "lg": $lg,
  "xl": $xl,
  "wide": $wide
) !default;

@mixin lessThan($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin moreThan($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
