#loginAndRegister {
  @extend .container;
  padding: var(--cg-gutter-md);
  display: grid;
  grid-gap: calc(var(--cg-gutter-md) * 2);
  grid-template-columns: 1fr;
  grid-template-areas: "left" "right";
  @include moreThan(sm) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "left right";
  }
  @include moreThan(lg) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: ". left left right right .";
  }
  input,
  button {
    background-color: white;
    border-radius: 0;
  }
  h1,
  h2 {
    font-size: var(--cg-text-xl);
    line-height: 1.33;
    margin-bottom: calc(var(--cg-gutter-md) * 1.5);
    font-weight: normal;
  }
  section {
    margin-bottom: calc(var(--cg-gutter-md) * 3);
  }
  p.error {
    margin: var(--cg-gutter-md) 0;
  }
  .left,
  .right {
    border-radius: 0;
    color: $cg-black;
    background-color: $cg-grey-lightest;
    label {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--cg-gutter-md);
      &.checkbox {
        flex-direction: row;
      }
    }
    button[type="submit"] {
      display: block;
      margin-top: calc(var(--cg-gutter-md) * 1.5);
    }
  }
  .left {
    grid-area: left;
    padding: var(--cg-gutter-md);
    #login {
    }
    #passwordReset {
      h2 {
        margin: var(--cg-gutter-md) 0;
      }
      label {
        display: flex;
        flex-direction: column;
      }
      input {
        margin-top: 0.5em;
      }
    }
  }
  .right {
    grid-area: right;
    padding: var(--cg-gutter-md);
    #register {
      .subscribe {
        flex-direction: row;
      }
    }
  }
}

#userPasswordReset {
  display: flex;
  justify-content: center;
  margin: var(--cg-gutter-md);
  // border: 1px solid $cg-primary;
  #content {
    display: flex;
    flex-direction: column;
    h2,
    label {
      margin-bottom: var(--cg-gutter-md);
    }
    label,
    h2 {
      display: flex;
      flex-direction: column;
    }
    input {
      margin-top: calc(var(--cg-gutter-md) / 2);
    }
  }
}
