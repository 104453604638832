#artists {
  @extend .container;
  padding: 0 $gutter;
  .header {
    padding: $gutter 0 $gutter 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: $gutter;
    @include lessThan(md) {
      flex-direction: column;
      max-width: 100%;
    }
    h1 {
      font-size: $text-size-xl;
      @include lessThan(lg) {
        margin-bottom: $gutter;
      }
    }
    .abecedaire {
      text-transform: uppercase;
      a:nth-child(1) {
        word-wrap: normal;
        word-break: keep-all;
        white-space: nowrap;
      }
      a {
        font-size: $text-size-lg;
        padding: 5px 10px 5px 0;
        &.active {
          font-weight: bold;
          text-decoration: underline;
        }
      }
      @include lessThan(md) {
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
      }
    }
  }
  .content {
    padding: $gutter 0 $gutter 0;
    .entry {
      margin-bottom: calc(var(--gutter) * 3);
      padding: calc(var(--cg-gutter-md) / 2);
      &.active {
        background-color: var(--cg-preset-background);
      }
      h3 {
        font-size: $text-size-lg;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: calc(var(--gutter) * 2);
      }
      p {
        margin-bottom: calc(var(--gutter) / 4);
      }
    }
  }
}
