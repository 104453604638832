h1 {
  font-size: var(--cg-text-xxl);
}

h2 {
  font-size: var(--cg-text-lg);
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

p {
  margin-bottom: calc(var(--cg-gutter-md) / 8);
}
