#summary,
#shipping,
#voucher {
  h2 {
    font-weight: normal;
  }
  hr {
    margin: var(--cg-gutter-md) 0;
  }
  background-color: $cg-grey-lightest;
  padding: var(--cg-gutter-md);
  margin-bottom: var(--cg-gutter-md);
  .justify {
    display: flex;
    justify-content: space-between;
    padding: calc(var(--cg-gutter-md) / 4) 0;
  }
}

#basketAndSummary {
  margin-top: calc(var(--cg-gutter-md) * 2);
  display: grid;
  grid-column-gap: var(--cg-gutter-md);
  grid-template-columns: 1fr;
  .basketMessage {
    color: var(--cg-danger-text);
    margin: var(--cg-gutter-md) 0;
    background-color: $cg-grey-lightest;
    padding: var(--cg-gutter-md);
  }
  section {
    margin: var(--cg-gutter-md) 0;
  }
  @include moreThan(lg) {
    grid-template-columns: 3fr 1fr;
    grid-row-gap: var(--cg-gutter-md);
  }

  #summaryAndShipping {
    #proceedToCheckout {
      width: 100%;
      background-color: var(--cg-ok-text);
      border: none;
      padding: calc(var(--cg-gutter-md) / 2);
      font-weight: bold;
    }
  }
}

#basket {
  #basketItems {
    display: grid;
    grid-row-gap: calc(var(--cg-gutter-md) / 2);

    .packageHeader {
      font-weight: bold;
    }
    #basketHeader.basketEntry {
      text-transform: uppercase;
      padding: calc(var(--cg-gutter-md) / 4);
      p {
        font-size: var(--cg-text-md);
      }
      @include lessThan(md) {
        grid-template-columns: 3fr 1fr;
        .quantity,
        .itemDescription,
        .total {
          display: none;
        }
      }
    }
    .basketEntry {
      border: none;
      &:not(:nth-child(1)) {
        border-top: 1px dotted $cg-black;
      }
      display: grid;
      grid-template-columns: 1fr 3fr;
      @include moreThan(md) {
        grid-template-columns: 2fr 6fr 1fr 2fr 1fr;
      }
      grid-column-gap: var(--cg-gutter-md);
      align-items: flex-start;
      padding: var(--cg-gutter-md) 0;
      .image {
      }
      .sale {
        @include lessThan(md) {
          display: none;
        }
        font-size: var(--cg-text-xl);
      }
      p.itemDescription {
        font-size: var(--cg-text-lg);
        @include lessThan(md) {
          font-size: var(--cg-text-md);
        }
        margin-bottom: var(--cg-gutter-md);
        font-weight: 700;
      }
      .options {
        font-size: var(--cg-text-md);
        @include lessThan(md) {
          font-size: var(--cg-text-sm);
        }
      }
      .quantity {
        @include lessThan(md) {
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
        input {
          text-align: center;
          padding: calc(var(--cg-gutter-md) / 2);
          background-color: $cg-grey-lightest;
        }
        .quantityEditor {
          display: flex;
          align-items: center;
          input {
            margin: 0 calc(var(--cg-gutter-md) / 2);
            max-width: 70px;
          }
          button {
            padding: 0;
            width: 20px;
            height: 20px;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .total {
        font-size: var(--cg-text-xl);
        @include lessThan(md) {
          text-align: right;
        }
      }
      form {
        label {
          font-weight: bold;
        }
        .inputGroup {
          margin-top: calc(var(--cg-gutter-md) / 2);
        }
      }
      .preorder {
        color: var(--cg-danger-text);
      }
      img {
        max-width: 100%;
      }
    }
    .packageShippingSelect {
      display: grid;
      grid-template-columns: 5fr 1fr;
      .left {
        display: flex;
        flex-direction: column;
      }
      .right {
        p {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

#freeShippingThreshold,
#shippingOptions,
#shippingRecap {
  h2 {
    font-weight: normal;
  }
  hr {
    margin: var(--cg-gutter-md) 0;
  }
  background-color: $cg-grey-lightest;
  padding: var(--cg-gutter-md);
  margin-bottom: var(--cg-gutter-md);
  address {
    margin-bottom: var(--cg-gutter-md);
  }

  h2 {
    margin-bottom: calc(var(--cg-gutter-md) / 2);
  }
  form {
    margin: calc(var(--cg-gutter-md) / 2) 0;
    #collectionPhrase {
      font-style: italic;
      margin-top: calc(var(--cg-gutter-md) / 2);
    }
  }
  #shippingToAddress {
    margin-bottom: calc(var(--cg-gutter-md) / 4);
  }
  #grouping {
    margin-left: var(--cg-gutter-md);
  }
}
