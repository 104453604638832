.priceAndBuy {
  border-radius: 2px;
  display: block;
  border: 1px solid var(--cg-cta-container);
  background-color: var(--cg-cta-container);
  padding: calc(var(--cg-gutter-xs) / 2);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: var(--cg-gutter-md);
  grid-template-areas:
    "options priceAndButton"
    "preorderNotice taxNotice"
    "listingComments listingComments";

  &.buyable {
    &:hover {
      background-color: var(--cg-cta-hover-container);
      color: var(--cg-cta-hover-text);
      &:active {
        background-color: var(--cg-cta-pressed-container);
        color: var(--cg-cta-pressed-text);
      }
    }
    cursor: pointer;
  }
  &.soldOut {
    &:hover {
      cursor: no-drop;
    }
  }

  .preorderNotice {
    text-transform: uppercase;
    font-size: var(--cg-text-xxs);
    align-self: center;
    font-weight: 700;
    letter-spacing: 0.6px;
    margin-bottom: calc((var(--cg-gutter-xs) / 2) / 2);
    grid-area: preorderNotice;
  }

  .options {
    grid-area: options;
    div {
      font-size: var(--cg-text-xs);
      line-height: $text-size-xs;
      display: block;
    }
    span.mediaTooltip {
      display: inline-block;
      background-color: var(--cg-preset-legal);
      border: 2px solid var(--cg-preset-legal);
      border-radius: 50%;
      width: 10px;
      height: 10px;
      padding: 2px;
      font-weight: bold;
      text-align: center;
      margin-left: 5px !important;
    }
    .media {
      .__react_component_tooltip.show {
        opacity: 1 !important;
      }
    }
  }

  .taxNotice {
    grid-area: taxNotice;
    p {
      color: var(--cg-preset-legal);
      font-size: var(--cg-text-xs);
      margin: 0;
    }
  }

  .priceAndButton {
    grid-area: priceAndButton;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .fullRow {
      flex-basis: 100%;
      width: 0;
      margin-top: calc(var(--cg-gutter-md) / 2);
    }
    .notAvailable {
      justify-self: flex-end;
      text-align: right;
      white-space: nowrap;
      align-self: flex-end;
      font-size: var(--cg-text-xl);
    }
    .addToBasket {
      display: inline-block;
      border: none;
      text-transform: uppercase;
      text-align: right;
      i {
        font-size: $text-size-lg;
      }
    }
    p.notAvailable {
      margin: 0;
    }
    h3 {
      font-weight: normal;
      font-size: var(--cg-text-xl);
      .discounted {
        margin-left: var(--cg-gutter-md);
        color: var(--cg-preset-legal);
        font-size: var(--cg-text-md);
      }
    }
  }

  .listingComments {
    grid-area: listingComments;
    font-size: var(--cg-text-xs);
  }
}
