@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animatedBackground {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: $cg-grey-lightest;
  background: linear-gradient(
    to right,
    #eeeeee 8%,
    $cg-grey-lightest 18%,
    #eeeeee 33%
  );
  background-size: 800px 104px;
  position: relative;
}
