.tile {
  img {
    width: 100%;
  }
  .priceAndWantlist {
    margin-top: calc(var(--cg-gutter-md) / 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      i {
        height: var(--cg-text-md);
        width: auto;
        margin-left: 5px;
      }
      padding: 0;
    }
    button.addToBasket {
      padding: 0 calc(var(--cg-gutter-md) / 2) 0 0;
      display: flex;
    }
  }
  .artistsAndTitle {
    margin-top: calc(var(--cg-gutter-md) / 4);
    margin-bottom: calc(var(--cg-gutter-md) / 2);
    .artistName {
      font-weight: 600;
    }
    p {
      word-break: break-all;
    }
  }
  .tracks {
    margin-top: calc(var(--cg-gutter-md) / 4);
    button {
      i {
        font-size: 11px;
      }
      margin-right: 5px;
    }
  }
  .manufacturerAndTitle {
    .manufacturer {
      font-weight: 600;
    }
  }
  .descriptionTextContainer {
    display: none;
  }
}
.listView {
  hr {
    margin: 0 0 var(--cg-gutter-md);
    border: 0;
    border-top: 1px dotted black;
  }
  & > .container {
    display: grid;
    @include moreThan(lg) {
      grid-column-gap: calc(var(--cg-gutter-xs));
      grid-row-gap: 0;
      grid-template-columns: repeat(9, minmax(0, 1fr));
      grid-template-areas:
        "thumbnail thumbnail information information information information information priceAndBasket priceAndBasket"
        "thumbnail thumbnail information information information information information priceAndBasket priceAndBasket"
        "thumbnail thumbnail description description description description description wantlist wantlist"
        "thumbnail thumbnail bottom bottom bottom bottom bottom bottom bottom";
    }
    @include lessThan(md) {
      grid-template-areas: "thumbnail" "information" "description" "priceAndBasket" "wantlist" "bottom";
    }
    .thumbnail {
      display: flex;
      flex-direction: column;
      grid-area: thumbnail;
      a img {
        padding-bottom: var(--cg-gutter-md);
        height: auto;
        width: 100%;
      }
    }
    .productInformation,
    .releaseInformation {
      grid-area: information;
      margin-bottom: var(--cg-gutter-md);
      .artistAndTitle {
        font-size: var(--cg-text-lg);
        overflow: hidden;
        .artist,
        .title {
          display: inline;
        }
        .title:before {
          content: " - ";
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .releaseDescription {
        margin-top: 5px;
        * {
          display: inline;
        }
      }
    }
    .priceContainer {
      text-align: right;
      grid-area: priceAndBasket;
      .discounted,
      .soldOutPrice {
        font-size: var(--cg-text-lg);
        letter-spacing: normal;
        line-height: normal;
      }
      .soldOutPrice {
        text-decoration: line-through;
        @include lessThan(md) {
          order: 1;
        }
      }
      .discounted {
        color: var(--cg-preset-legal);
        @include lessThan(md) {
          margin-right: 0;
          margin-left: var(--cg-gutter-xs);
        }
      }
      .priceAndBasket {
        padding: 0 !important;
        @include lessThan(md) {
          order: 3;
          display: flex;
          align-items: flex-start;
        }
        .price,
        .soldOut,
        .soldOutPrice {
          letter-spacing: normal !important;
          font-size: var(--cg-text-lg);
          text-align: right;
          margin-left: 5px;
        }
        .priceButtonHover {
          align-self: center;
          margin-top: 5px;
          margin-left: 5px;
          .hiddenBasket {
            display: none;
          }
          i {
            margin-left: 5px;
          }
        }
      }
      &:hover .buttonsContainer .addToBasket,
      &:hover .priceButton .price {
        border-bottom: 1px solid $cg-black;
      }
    }
    .wantlistContainer {
      text-align: right;
      grid-area: wantlist;
    }
    .priceContainer,
    .wantlistContainer {
      @include lessThan(md) {
        margin-top: var(--cg-gutter-xs);
        display: flex;
        justify-content: space-around;
        padding: calc(var(--cg-gutter-xs) / 2);
        border: 1px solid black;
      }
    }
    .buttonsContainer {
      display: inline-block;
      text-align: right;
      @include lessThan(md) {
        display: flex;
        margin-bottom: var(-cg-gutter-md);
        justify-content: flex-end;
        order: 1;
      }
      a,
      button,
      p,
      span {
        line-height: 1.2;
        padding: 0;
        svg {
          height: 15px;
          width: 15px;
          margin-left: 5px;
        }
      }
      .addToWantlist:hover {
        border-bottom: 1px solid $cg-black;
      }
      .addToBasket {
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 0;
        @include moreThan(lg) {
          display: block;
        }
        @include lessThan(md) {
          display: inline-block;
        }
      }
      .addToWantlist {
        svg {
          margin-bottom: -1px;
        }
      }
    }
    .artist,
    .productName {
      font-weight: 600;
    }
    .manufacturer {
      font-size: var(--cg-text-md);
    }
    .productDescription,
    .shopDescription {
      display: inline-block;
      text-align: left;
      overflow: hidden;
      grid-area: description;
      .descriptionWrapper {
        text-align: justify;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .bottom {
      grid-area: bottom;
      margin-top: var(--cg-gutter-md);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-end;
      padding-bottom: var(--cg-gutter-md);
      .tracklist {
        width: 100%;
        margin-top: calc(var(--cg-gutter-md) / 2);
        display: column;
        column-count: 1;
        column-gap: var(--cg-gutter-xs);
        @include moreThan(lg) {
          grid-template-columns: 2fr 2fr;
          column-count: 2;
        }
        .trackEntry {
          -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
          break-inside: avoid;
        }
      }
    }
  }
}

.fullView {
  display: grid;
  img {
    width: 100%;
  }
  .title {
    grid-area: title;
  }
  .tracklist {
    grid-area: tracklist;
  }
  .descriptions {
    grid-area: description;
  }
  .summary {
    grid-area: summary;
  }
  .primaryImage {
    grid-area: primaryImage;
  }
  .secondaryImages {
    grid-area: secondaryImages;
  }
  .carousel {
    grid-area: carousel;
  }
  .buyButtons {
    grid-area: buyButtons;
  }
  .moreInfo {
    grid-area: moreInfo;
  }
  .addToWantlist {
    grid-area: addToWantlist;
  }
  .snippetDownload {
    grid-area: snippetDownload;
  }
}
