@mixin centralTextContainer {
  max-width: 60vw;
  margin: 0 auto;
}

.articleRows {
  .row {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(var(--gutter) * 2);
    max-width: 60vw;
    @include lessThan(lg) {
      max-width: none;
    }
    &.fullWidth {
      max-width: none;
    }
    grid-gap: $gutter;
    &.noPadding {
      grid-gap: 0;
    }
    &:not(.noPadding) {
      padding: $gutter;
    }
    &.oneColumn {
      display: grid;
      grid-template-columns: 1fr;
    }
    &.twoColumn {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include lessThan(lg) {
        grid-template-columns: 1fr;
      }
    }
    &.threeColumn {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      @include lessThan(lg) {
        grid-template-columns: 1fr;
      }
    }
    .column {
      .block.text {
        padding: $gutter 0;
        & > h1 {
          text-align: center;
        }
      }
      .block.playlist {
        h2 {
          text-align: center;
        }
      }
      .block.article {
        img {
          max-width: 100%;;
        }
      }
      .block.items {
        .entries {
          display: grid;
          grid-gap: var(--gutter-md);
        }
        .header {
          text-align: center;
          padding: var(--cg-gutter-md);
        }
      }
      .block.collection {
        h2,
        .footer {
          text-align: center;
        }
      }
      .block.image {
        img {
          max-width: 100%;
        }
      }
      &.block.separator {
        hr {
          border: 1px solid var(--cg-preset-legal);
        }
      }
      .block.subscribe {
        margin: calc(var(--gutter) * 2) auto;
        background-color: var(--cg-preset-background);
        padding: var(--gutter);
        h2 {
          margin-bottom: calc(var(--gutter) * 1.5);
          font-size: $cg-text-xl;
          @include lessThan(lg) {
            font-size: $cg-text-lg;
          }
        }
        .content {
          display: flex;
          justify-content: space-between;
          @include lessThan(lg) {
            flex-direction: column;
            button {
              margin-top: $gutter;
            }
          }
          label {
            display: flex;
            flex-direction: column;
            width: 70%;
          }
          button {
            border-color: var(--cg-primary);
          }
        }
      }
    }
  }
}
