@import "fonts";
@import "buttons";
@import "spinner";
@import "input";
@import "pagination";
@import "search";
@import "blocks";
@import "price";
@import "carousel";
@import "modal";
@import "text";
@import "subscribe";
@import "buyButton";

#suggestions {
  h2 {
    margin-bottom: var(--cg-gutter-md);
    font-weight: normal;
  }
  margin-top: calc(var(--cg-gutter-md) * 2);
  #suggestionEntries {
    display: grid;
    grid-gap: var(--cg-gutter-md);
    grid-template-columns: repeat(2, 1fr);
    @include moreThan(lg) {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      grid-row-gap: var(--cg-gutter-md);
    }
  }
}

.inputGroup {
  display: flex;
}
