#demoNotice {
  cursor: pointer;
  position: fixed;
  background-color: $cg-blue-lighter;
  font-size: var(--cg-text-md);
  padding: var(--cg-gutter-md);
  bottom: 0;
  width: 100vw;
  color: var(--cg-cookies-text);
  text-align: center;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  i {
    margin: 0 calc(var(--cg-gutter-md) / 2);
  }
  @include moreThan(lg) {
    font-size: var(--cg-text-lg);
  }
}
