#collection {
  padding-bottom: calc(var(--cg-gutter-md) * 2);
  & > .banner {
    img {
      width: 100%;
    }
  }
  & > .header {
    text-align: center;
    padding: calc(var(--cg-gutter-md) * 2);
  }
  & > .description {
    text-align: center;
    padding: calc(var(--cg-gutter-md) * 1);
    max-width: 50%;
    @include lessThan(md) {
      max-width: 80%;
    }
    margin: 0 auto;
  }
  & > #collectionContentWrapper > .content {
    padding: 0 var(--cg-gutter-md);
    @extend .container;
    .entries {
      display: grid;
      grid-gap: var(--cg-gutter-md);
      justify-content: space-between;
      grid-template-columns: repeat(2, 1fr);
      @include moreThan(sm) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include moreThan(md) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include moreThan(lg) {
        grid-template-columns: repeat(6, 1fr);
      }
    }
    .pagination {
      &:not(.mobile) {
        padding: var(--cg-gutter-md) 0;
        h1,
        p,
        a,
        li {
          font-size: var(--cg-text-lg);
          font-weight: 400;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
        .right {
          align-items: center;
          display: flex;
          justify-content: space-between;
          .page {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .pageCount {
              @include lessThan(md) {
                display: none;
              }
            }
            .perPage {
              margin-left: $gutter;
              font-size: $cg-text-lg;
              display: flex;
              align-items: baseline;
              justify-content: space-between;
              span {
                color: $cg-grey-light;
              }
              button {
                font-size: inherit;
                line-height: 1;
                &:not(.active) {
                  color: $cg-grey-light;
                }
                padding: 0 3px;
              }
            }
            #viewSwitch {
              margin-left: var(--cg-gutter-md);
              display: flex;
              align-items: center;
              button {
                &:nth-child(1) {
                  margin-right: var(--cg-gutter-md);
                }
                padding: 0;
                border-radius: 0;
                i {
                  font-size: $text-size-lg;
                }
              }
            }
          }
          ul.naviguator {
            display: flex;
            li {
              padding: 0;
              color: $cg-black;
              margin-left: var(--cg-gutter-md);
            }
          }
        }
      }
      .sorter {
        // @include lessThan(md) {
        //   display: none;
        // }
        .defaultElement {
          display: flex;
        }
        position: relative;
        display: flex;
        align-items: center;
        button {
          display: flex;
          align-items: center;
          padding: 5px;
          padding-top: 0;
          line-height: 1;
          font-size: $cg-text-lg;
          letter-spacing: inherit;
          text-transform: inherit;
          i {
            margin-left: 5px;
          }
        }
        .menu {
          width: 300px;
          z-index: 3;
          -webkit-user-select: none; /* webkit (safari, chrome) browsers */
          -moz-user-select: none; /* mozilla browsers */
          -khtml-user-select: none; /* webkit (konqueror) browsers */
          -ms-user-select: none; /* IE10+ */
          position: absolute;
          top: 30px;
          background-color: var(--cg-cta-container);
          border: 1px solid black;
          display: none;
          @include moreThan(md) {
            display: block;
          }
          ul {
            display: flex;
            flex-direction: column;
            padding: 10px;
            li {
              &.active {
                text-decoration: underline;
              }
              a {
                font-size: $cg-text-md;
              }
              padding: 5px;
              margin: 0;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      &.mobile {
        display: flex;
        flex-direction: column;
        margin: var(--gutter) 0;
        a,
        p {
          font-weight: normal;
        }
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: var(--gutter) 0;
          .pageCount {
            font-size: $cg-text-md;
          }
          .left {
            display: flex;
            align-items: baseline;
            p {
              margin: 0;
              line-height: 1;
              vertical-align: baseline;
            }
            .loader {
              margin-left: var(--gutter);
            }
          }
          .viewSwitch {
            button {
              padding: 0 calc(var(--cg-gutter-md) / 2);
            }
          }
          .naviguator {
            li {
              padding: 0;
              padding-left: calc(var(--gutter) / 2);
              &.disabled {
                opacity: 0.2;
              }
              a {
                font-weight: normal;
                font-size: $cg-text-md;
              }
            }
          }
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          border-top: 1px solid $cg-black;
          padding-top: 20px;
          align-items: center;
          .sorter {
            .defaultElement {
              display: flex;
            }
            button {
              display: flex;
              align-items: center;
              padding: 5px;
              padding-top: 0;
              padding-left: 0;
              line-height: 1;
              font-size: $cg-text-md;
              letter-spacing: inherit;
              text-transform: inherit;
              i {
                margin-left: 5px;
              }
            }
            .menu {
              display: flex;
              flex-direction: column;
              a {
                font-weight: normal;
                font-size: $cg-text-md;
              }
              ul {
                display: flex;
                flex-direction: column;
                li {
                  padding: 0;
                  color: $cg-grey-light;
                  &.active {
                    color: $cg-black;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.collection {
  @extend .container;

  .content {
    .header {
      text-align: center;
      padding: var(--cg-gutter-md);
    }
    .entries {
    }
    .footer {
      display: flex;
      justify-content: center;
    }
    ul.naviguator {
      display: flex;
      justify-content: flex-end;
      li {
        padding: 0;
        color: $cg-black;
        margin-left: var(--cg-gutter-md);
      }
    }
  }
}
