#maintenance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  color: $cg-primary;
  h1 {
    font-size: 50px;
    margin-bottom: $gutter;
  }
  #login {
    margin-top: calc(var(--cg-gutter-md) * 4);
    input {
      display: flex;
      flex-direction: column;
      background-color: $cg-grey;
      font-size: var(--cg-text-md);
    }
  }
}
