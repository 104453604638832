#unsubscribe {
  margin-top: var(--cg-gutter-md);
  padding: var(--cg-gutter-md);
  max-width: 60vw;
  margin-left: auto;
  margin-right: auto;
  h2 {
    font-size: $text-size-md;
  }
  #resetMessage {
    margin: var(--cg-gutter-md) 0;
  }
  form {
    margin-top: calc(var(--cg-gutter-md) * 2);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--cg-gutter-md);
    label {
      display: flex;
      flex-direction: row;
      input {
        margin: 0;
        margin-right: calc(var(--cg-gutter-md) / 2)
      }
    }
    .submit {
      display: flex;
    }
    button {
      margin-top: var(--cg-gutter-md);
    }
  }
}
