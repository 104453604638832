#player {
  background-color: var(--cg-audio-background);
  color: var(--cg-audio-text);
  &.hidden {
    display: none;
  }
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  position: sticky;
  z-index: 2;
  #playerContent {
    @extend .container;
    padding: calc(var(--cg-gutter-md) / 2) var(--cg-gutter-md);
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "left left"
      "waveform right";
    align-items: center;
    grid-gap: calc(var(--cg-gutter-md) / 2);
    @include moreThan(lg) {
      padding: calc(var(--cg-gutter-md) / 4) var(--cg-gutter-md);
      grid-template-columns: 2fr 3fr 0.4fr;
      grid-template-areas: "left waveform right";
    }
    z-index: 2;
    #left {
      grid-area: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      #artwork {
        width: 50px;
        height: auto;
        img {
          object-fit: cover;
          width: 100%;
          max-height: 100%;
        }
        @include lessThan(md) {
          display: none;
        }
      }
      a {
        white-space: nowrap;
        &:hover {
          border-bottom: 1px dotted;
        }
      }
      .loader {
        margin: 0;
        margin-left: calc(var(--cg-gutter-md) * 2);
        margin-right: calc(var(--cg-gutter-md) * 2);
      }
      #playerTitle {
        margin-left: calc(var(--cg-gutter-md) / 2);
      }
      .controls {
        display: flex;
        align-items: center;
        button.playPauseButton {
          font-size: $text-size-xxl;
          margin-top: 3px;
        }
        button:not(.playPauseButton) {
          font-size: $text-size-sm;
        }
      }
    }

    #waveform {
      grid-area: waveform;
      width: 100%;
      overflow: hidden;
      & > wave {
        overflow: hidden;
      }
    }
    #right {
      grid-area: right;
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        background-color: transparent;
        border: none;
        padding: 0;
        img {
          height: auto;
          width: var(--cg-text-xl);
        }
      }
    }
  }
}
