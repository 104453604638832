.defaultFooter {
  @include moreThan(lg) {
    position: absolute;
    bottom: 0;
  }
  width: 100%;
  background-color: var(--cg-footer-background);
  .content {
    margin: 0 auto;
    max-width: 1440px;
    padding: calc(var(--gutter) * 2) var(--gutter);

    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: repeat(5, 1fr);
    @include lessThan(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    .subscribe {
      text-align: left;
      padding-left: 0;
    }
    .group {
      display: flex;
      flex-direction: column;
      & > * {
        text-transform: uppercase;
        font-weight: 400;
        line-height: 1.5;
        font-size: var(--cg-text-sm);
      }
    }
  }
}
