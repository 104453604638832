#forbidden {
  text-align: center;
  padding: var(--cg-gutter-md);
}
#itemPage {
  padding: 0 var(--cg-gutter-md);
  @extend .container;
  #item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "header header header"
      "thumbnail information buyAndWantlist"
      "thumbnail description buyAndWantlist"
      "thumbnail description tracklist"
      "thumbnail description tracklist"
      "thumbnail videos ."
      "suggestions suggestions suggestions";
    grid-gap: var(--cg-gutter-md);
    @include lessThan(lg) {
      grid-template-columns: 1fr;
      grid-template-areas: "header" "thumbnail" "information" "description" "buyAndWantlist" "tracklist" "videos" "suggestions";
    }
    h2 {
      margin-bottom: var(--cg-gutter-md);
      font-size: var(--cg-text-md);
      font-weight: normal;
    }
    #itemHeader,
    .itemHeader {
      grid-area: header;
      padding: var(--cg-gutter-md) 0;
      h1 {
        font-weight: normal;
        line-height: 1.3;
        font-size: $text-size-xxl;
        .medium {
          font-weight: 600;
        }
      }
    }
    .thumbnailAndImages {
      grid-area: thumbnail;
      img#mainImage {
        width: 100%;
      }
      .images {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-between;
        margin-top: var(--cg-gutter-md);
        grid-gap: var(--cg-gutter-md);
        img {
          // margin: var(--cg-gutter-md);
          max-width: 100%;
          height: auto;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .itemSpecs {
      grid-area: information;
      .itemInfo {
        display: grid;
        grid-template-columns: 1fr 3fr;
      }
    }
    .videos {
      display: none;
      grid-area: videos;
      margin-top: var(--gutter);
      grid-template-columns: 1fr;
      grid-gap: var(--gutter);
    }
    #shopDescription,
    .shopDescription {
      grid-area: description;
      p {
        text-align: left;
      }
      .shortDescription {
        display: none;
      }
    }
    .buyAndWantlist {
      align-self: start;
      grid-area: buyAndWantlist;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--cg-gutter-md);
      @include lessThan(lg) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .priceAndBuy,
        .wantlistAndButton {
          flex-basis: 100%;
        }
      }
      section#downloadSnippet {
        display: grid;
        grid-gap: var(--cg-gutter-md);
        grid-template-columns: 1fr 1fr;
        @include lessThan(md) {
          grid-template-columns: 1fr;
        }
      }
      section.wantlistAndButton {
        button {
          width: 100%;
          text-transform: uppercase;
          padding: calc(var(--cg-gutter-md) / 2);
          vertical-align: baseline;
          line-height: 1.8;
          margin-bottom: var(--cg-gutter-md);
          i {
            right: 0;
            height: 20px;
            padding: 1px;
            float: right;
            font-size: $text-size-lg;
          }
        }
      }
    }
    #suggestions,
    .suggestions {
      grid-area: suggestions;
    }
    #tracklist,
    .tracklist {
      align-self: start;
      grid-area: tracklist;
      @include lessThan(lg) {
        width: 100%;
      }
    }
  }
}
