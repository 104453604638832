#labels {
  .container {
    padding: $gutter;
    min-height: 90vh;
    padding-bottom: calc(var(--cg-gutter-md) * 2);
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: calc(var(--cg-gutter-md) * 3);
      @include lessThan(md) {
        flex-direction: column;
        max-width: 100%;
      }
      h1 {
        font-size: $text-size-xl;
        @include lessThan(lg) {
          margin-bottom: $gutter;
        }
      }
      .abecedaire {
        text-transform: uppercase;
        a:nth-child(1) {
          word-wrap: normal;
          word-break: keep-all;
          white-space: nowrap;
        }
        a {
          font-size: $text-size-lg;
          padding: 5px 10px 5px 0;
          &.active {
            font-weight: bold;
            text-decoration: underline;
          }
        }
        @include lessThan(md) {
          overflow-wrap: break-word;
          word-wrap: break-word;

          -ms-word-break: break-all;
          word-break: break-all;
          word-break: break-word;
        }
      }
    }
    .content {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      @include lessThan(wide) {
        grid-template-columns: repeat(8, 1fr);
      }
      @include lessThan(lg) {
        grid-template-columns: repeat(6, 1fr);
      }
      @include lessThan(md) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include lessThan(sm) {
        grid-template-columns: repeat(2, 1fr);
      }
      grid-gap: calc(var(--cg-gutter-md));
      .entry {
        .img {
          position: relative;
          height: 150px;
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            margin-bottom: $gutter;
          }
        }
        p {
          font-weight: bold;
          margin-top: calc(var(--gutter) / 4);
        }
      }
    }
  }
}
