.playlist {
  margin-bottom: var(--cg-gutter-md);
  h2 {
    margin-bottom: var(--cg-gutter-md);
    font-size: var(--cg-text-xl);
  }

  &.dummy {
    min-height: 150px;
    .title {
      @extend .animatedBackground;
      margin-bottom: var(--cg-gutter-md);
      height: 20px;
    }
    .entries {
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: calc(var(--cg-gutter-md) * 4);
      .item {
        min-height: 130px;
        max-width: 100%;
        background-color: $cg-grey-lightest;
      }
    }
  }
}

.playlist,
.collection {
  .entries {
    display: grid;
    grid-gap: var(--cg-gutter-md);
    grid-template-columns: repeat(2, 1fr);
    @include moreThan(md) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    @include moreThan(lg) {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
  }
}
