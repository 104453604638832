.ReactModal__Overlay {
  z-index: 3;
}
.ReactModalPortal {
  .modal {
    position: absolute;
    top: 2em;
    left: 2em;
    right: 2em;
    bottom: 2em;
    background-color: white;
    outline: none;
    border: solid 2px $cg-primary;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($cg-grey-light, 0.7);
    outline: none;
  }
  .ReactModal__Content {
    @include lessThan(md) {
      max-width: 80%;
    }
    .modalHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--cg-gutter-md);
      button {
        padding: 0;
        margin: 0;
        margin-left: calc(var(--cg-gutter-md) * 2);
        font-size: var(--cg-text-md);
        border: 0;
        background-color: transparent;
        svg {
          height: var(--cg-text-md);
        }
      }
    }
    .modalContent {
      width: 100%;
      textarea {
        margin-top: 1em;
        padding: 0;
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
}
