@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");

@font-face {
  font-family: "cg-icons";
  src: url("https://static.common-ground.io/common/fonts/eshop/cg-font-eshop-regular.woff2?v=1")
    format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// unicode-range: U+000-5FF;

[class^="cg-icon-"],
[class*=" cg-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "cg-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  // /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/****** CG Icons ******/

.cg-icon-search-mobile:before {
  content: "\ea00";
}
.cg-icon-view-grid-mobile:before {
  content: "\ea01";
}
.cg-icon-filter-mobile:before {
  content: "\ea02";
}
.cg-icon-view-list-mobile:before {
  content: "\ea03";
}
.cg-icon-view-list:before {
  content: "\ea04";
}
.cg-icon-view-grid:before {
  content: "\ea05";
}
.cg-icon-search:before {
  content: "\ea06";
}
.cg-icon-catalogue-down:before {
  content: "\ea07";
}
.cg-icon-filter-less:before {
  content: "\ea08";
}
.cg-icon-filter-close:before {
  content: "\ea09";
}
.cg-icon-filter-more:before {
  content: "\ea0a";
}
.cg-icon-burger:before {
  content: "\ea0b";
}
.cg-icon-burger-close:before {
  content: "\ea0c";
}
.cg-icon-play:before {
  content: "\ea0d";
}
.cg-icon-pause:before {
  content: "\ea0e";
}
.cg-icon-player-prev:before {
  content: "\ea0f";
}
.cg-icon-player-play:before {
  content: "\ea10";
}
.cg-icon-player-pause:before {
  content: "\ea11";
}
.cg-icon-player-next:before {
  content: "\ea12";
}
.cg-icon-basket-cart:before {
  content: "\ea13";
}
.cg-icon-basket-cart-preorder:before {
  content: "\ea14";
}
.cg-icon-basket-selected:before {
  content: "\ea15";
}
.cg-icon-basket-add:before {
  content: "\ea16";
}
.cg-icon-basket-remove:before {
  content: "\ea17";
}
.cg-icon-wantlist:before {
  content: "\ea18";
}
.cg-icon-wantlist-fill:before {
  content: "\ea19";
}
// shipping	ea1a
// player-close	ea1b
// cookies-close	ea1c
.cg-icon-dictionary:before {
  content: "\ea1d";
}
