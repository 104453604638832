:root {
  --cg-primary: #8c5ffc;
  --cg-secondary: #79fece;
  --cg-black: #000000;
  --cg-font: "Graphik Web";
  --gutter: 1.3rem;

  --cg-background: #f7f7f7;
  --cg-legal: #f7f7f7;
  --cg-border-radius: 0px;
}

$cg-primary: var(--cg-primary);
$cg-secondary: var(--cg-secondary);

$cg-grey: #b5b5b5;
$cg-grey-light: #d2d2d2;
$cg-grey-lighter: #f7f7f7;

$border-radius: var(--cg-global-border-radius);
$border-style: var(--cg-global-border-style);

$gutter-xxs: var(--cg-gutter-xxs);
$gutter-xs: var(--cg-gutter-xs);
$gutter-sm: var(--cg-gutter-sm);
$gutter: var(--cg-gutter-md);
$gutter-md: $gutter;
$gutter-lg: var(--cg-gutter-lg);
$gutter-xl: var(--cg-gutter-xl);
$gutter-xxl: var(--cg-gutter-xxl);

$font-letter-spacing-lg: 1.67px;
$text-size-xxs: var(--cg-text-xxs);
$text-size-xs: var(--cg-text-xs);
$text-size-sm: var(--cg-text-sm);
$text-size-md: var(--cg-text-md);
$text-size-input: var(--cg-text-md);
$text-size-lg: var(--cg-text-lg);
$text-size-xl: var(--cg-text-xl);
$text-size-xxl: var(--cg-text-xxl);
$text-size-xxxl: calc(var(--cg-text-xxl) * 1.5);
