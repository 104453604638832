.price {
  &.discounted {
    text-decoration: line-through;
    margin-left: calc(var(--cg-gutter-md) / 4);
  }
}
.priceContainer {
  .price {
    &.discounted {
      font-size: var(--cg-text-md);
      margin-right: calc(var(--cg-gutter-md) / 4);
      opacity: 0.5;
    }
  }
}
