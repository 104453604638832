#home {
  @extend .container;
  padding: calc(var(--cg-gutter-md) / 2) var(--cg-gutter-md);
  #header {
    padding: var(--cg-gutter-md) 0;
    padding-bottom: calc(var(--cg-gutter-md) / 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dotted black;
    margin-bottom: calc(var(--gutter) * 2);
    @include lessThan(md) {
      display: grid;
    }
    a,
    button {
      @include lessThan(lg) {
        margin-left: 0;
        &.logout {
          margin-left: var(--cg-gutter-md);
        }
      }
      margin-left: var(--cg-gutter-md);
      text-transform: uppercase;
      padding: 0;
      font-size: var(--cg-text-sm);
      font-weight: 700;
      line-height: 1.3;
    }
    #left {
      align-items: center;
      display: flex;
      h1 {
        font-weight: normal;
        font-size: $text-size-xl;
        margin-right: calc(var(--cg-gutter-md) * 2);
      }
      & > * {
        margin-right: calc(var(--cg-gutter-md) * 2);
      }
      @include lessThan(md) {
        display: grid;
        align-items: flex-start;
        grid-gap: 5px;
        button {
          text-align: left;
        }
      }
    }
    #right {
    }
  }
}
