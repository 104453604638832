#articles {
  padding: $gutter;
  .articles {
    display: grid;
    grid-gap: $gutter;
    grid-template-columns: repeat(4, 1fr);
    @include lessThan(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    .article {
      display: flex;
      flex-direction: column;
      @include moreThan(lg) {
        justify-content: space-between;
      }

      & > * {
        margin-bottom: $gutter;
      }
      @include lessThan(lg) {
        h2 {
          font-size: $text-size-md;
        }
      }
      .header {
        min-height: 130px;
        img {
          width: 100%;
        }
      }
      .content {
        h2 {
          font-weight: bold;
          line-height: 32px;
        }
        grid-gap: $gutter;
        display: grid;
        .description {
          font-size: $text-size-lg;
          line-height: 25px;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        font-size: $text-size-sm;
        @include lessThan(lg) {
          display: none;
        }
      }
    }
  }
}
