#orders {
  @extend .container;
  padding: var(--cg-gutter-md);
  #header {
    margin-bottom: var(--cg-gutter-md);
  }
  #noOrders {
    padding: calc(var(--cg-gutter-md) / 2) var(--cg-gutter-md);
  }
}

#order {
  @extend .container;
  padding: var(--cg-gutter-md);
  .loader {
    text-align: center;
    margin-top: 10%;
  }
  #content {
    display: grid;
    grid-template-columns: 1fr;
    @include moreThan(lg) {
      grid-template-columns: 1fr 2fr;
    }
    grid-column-gap: var(--cg-gutter-md);
    #left {
      .status {
        margin-bottom: var(--cg-gutter-md);
        font-weight: bold;
      }
      h1 {
        font-size: var(--cg-text-lg);
        margin-bottom: var(--cg-gutter-md);
        font-weight: normal;
      }
      #placeWait,
      h3 {
        text-align: center;
      }
      #stripeCheckout,
      #paypalCheckout {
        margin-bottom: calc(var(--cg-gutter-md) * 2);
        h2 {
          margin: var(--cg-gutter-md) 0;
        }
      }
      #stripeCheckout {
        #stripeHeader {
          display: flex;
          justify-content: space-between;
          #stripeHeaderRight {
            font-size: var(--cg-text-xl);
            display: flex;
            align-items: center;
            img {
              color: black;
              margin-left: calc(var(--cg-gutter-md) / 2);
              width: auto;
              height: 30px;
            }
            svg {
              width: 15px;
              height: auto;
              color: black;
              margin-left: calc(var(--cg-gutter-md) / 2);
            }
          }
        }
        .StripeElement {
          border: 1px solid $cg-black;
          padding: calc(var(--cg-gutter-md) / 2);
          margin-top: calc(var(--cg-gutter-md) / 2);
        }
        .error {
          color: var(--cg-preset-danger);
        }
        button {
          width: 100%;
          background-color: $cg-black;
          color: white;
          padding: 10px;
          margin-top: calc(var(--cg-gutter-md) / 2);
        }
      }
    }

    #right {
      #items {
        display: grid;
        grid-row-gap: calc(var(--cg-gutter-md) / 8);
        #itemsHeader {
          & > *:not(.title) {
            text-transform: uppercase;
          }
          .title {
            font-size: var(--cg-text-lg);
          }
        }
        .entry {
          border-top: 1px dotted $cg-black;
          .quantity,
          .description {
            font-size: var(--cg-text-lg);
            .options {
              margin-top: var(--cg-gutter-md);
              font-size: var(--cg-text-md);
            }
          }
          .price {
            font-size: var(--cg-text-xl);
          }
        }
        .entry,
        #itemsHeader {
          button {
            margin: 0;
          }
          p {
            font-weight: normal;
          }
          padding: var(--cg-gutter-md) 0;
          display: grid;
          grid-template-columns: 1fr 4fr 1fr 1fr;
          grid-column-gap: calc(var(--cg-gutter-md) / 2);
          align-items: center;
          .image {
            max-width: 100%;
            img {
              width: 100%;
            }
          }
        }
      }
      #packagesContainer {
        margin: var(--cg-gutter-md) 0;
      }
      #messagesContainer {
        margin-top: calc(var(--cg-gutter-md) * 2);
        h2 {
          font-weight: normal;
        }
        #newMessage {
          textarea {
            border: none;
            display: block;
            width: 100%;
            outline: none;
            margin-top: var(--cg-gutter-md);
          }
          button {
            margin-top: var(--cg-gutter-md);
          }
        }
        .loader {
          margin: 0 var(--cg-gutter-md);
        }
        #orderMessages {
          margin-top: var(--cg-gutter-md);
          .message {
            padding: calc(var(--cg-gutter-md) / 2);
            .header {
              font-weight: bold;
              margin-bottom: calc(var(--cg-gutter-md) / 2);
              svg {
                margin-left: calc(var(--cg-gutter-md) / 2);
              }
            }
            &:nth-child(odd) {
              background-color: $cg-grey-lightest;
            }
          }
        }
      }
    }
  }
}

#orderTable {
  display: grid;
  .orderTableItem {
    &.header {
      font-weight: normal;
      .title {
        font-size: var(--cg-text-lg);
      }
      & > *:not(.title) {
        text-transform: uppercase;
      }
      font-size: var(--cg-text-md);
      border-bottom: 1px dotted $cg-black;
      margin-bottom: calc(var(--cg-gutter-md) * 2);
      padding: var(--cg-gutter-md) 0;
    }
    font-size: var(--cg-text-lg);
    display: grid;
    padding: calc(var(--cg-gutter-md) / 2);
    align-items: center;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    @include moreThan(lg) {
      grid-template-columns: 1.5fr 1fr 2fr 1fr 1fr 0.5fr;
      .description {
        display: none;
      }
    }
    grid-column-gap: calc(var(--cg-gutter-md) / 4);
    .origin {
      @include lessThan(lg) {
        display: none;
      }
      span img {
        max-width: 15px;
        margin-right: calc(var(--cg-gutter-md) / 4);
        height: auto;
      }
    }
    .created {
      @include lessThan(lg) {
        display: none;
      }
    }
    &:nth-child(even) {
      background-color: $cg-grey-lightest;
    }
    p {
      margin: 0;
    }
    .action {
      text-transform: uppercase;
      font-size: var(--cg-text-sm);
      font-weight: 700;
    }
  }
}
